import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    component: () => import('../views/yachtcharter/Home'),
    redirect: '/yachtcharter/home'
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/old_AboutView.vue')
  // },
  // {
  //   path: '/search-results',
  //   name: 'search-results',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SearchResults.vue')
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  // {
  //   path: '/croatia',
  //   name: 'croatia',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/yachtcharter/landingpages/Croatia.vue')
  // },
  // {
  //   path: '/impressum',
  //   name: 'impressum',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
  // },
  // {
  //   path: '/agbs',
  //   name: 'agbs',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Agbs.vue')
  // },
  // // {
  // //   path: '/cookies',
  // //   name: 'cookies',
  // //   component: () => import(/* webpackChunkName: "about" */ '../views/Cookies.vue')
  // // },
  // {
  //   path: '/datenschutz',
  //   name: 'datenschutz',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Datenschutz.vue')
  // },
  {
    path: '/yachtcharter',
    name: 'yachtcharter',
    component: () => import('../views/yachtcharter/Layout.vue'),
    redirect: '/yachtcharter/home',
    children: [
      {
        path: '/yachtcharter/home',
        component: ()=> import('../views/yachtcharter/Home.vue')
      },
      {
        path: '/yachtcharter/search-results',
        component: () => import('../views/yachtcharter/SearchResults.vue')
      },
      {
        path: '/yachtcharter/search-results-croatia',
        component: () => import('../views/yachtcharter/resultpages/Croatia.vue')
      },
      {
        path: '/yachtcharter/search-results-italy',
        component: () => import('../views/yachtcharter/resultpages/Italy.vue')
      },
      {
        path: '/yachtcharter/search-results-lastMinute',
        component: () => import('../views/yachtcharter/resultpages/LastMinute.vue')
      },      
      {
        path: '/yachtcharter/impressum',
        component: () => import('../views/yachtcharter/Impressum.vue')
      },
      {
        path: '/yachtcharter/agbs',
        component: () => import('../views/yachtcharter/Agbs.vue')
      },
      {
        path: '/yachtcharter/datenschutz',
        component: () => import('../views/yachtcharter/Datenschutz.vue')
      },
      {
        path: '/yachtcharter/croatia',
        component: () => import('../views/yachtcharter/landingpages/Croatia.vue')
      },
      {
        path: '/yachtcharter/yacht/:yachtId',
        component: () => import('../views/yachtcharter/landingpages/Yacht.vue')
      },
    ]
  },
  {
    path: '/yachtbroker',
    name: 'yachtbroker',
    component: () => import('../views/yachtbroker/Layout.vue'),
    redirect: '/yachtbroker/home',
    children: [
      {
        path: "/yachtbroker/home",
        component: () => import('../views/yachtbroker/Home.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
