export default {
    state: {
        user: 'Moni',
    
      },

    getters: {
        user: state => state.user,
    },
    
    mutations: {
        setUser(state, payload){
            console.log('Mutation called')
            state.user = payload
            console.log(' StoreModul User - Status changed to: ', state.user)
        }
    },

    actions: {
        signup(){
            
        }
    },

    modules: {
    }
}