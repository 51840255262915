import { createApp, useAttrs } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'


//----- Import Tailwind CSS - File
import './assets/tailwind.css'

//----- Import Vue Datapicker
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
//https://vue3datepicker.com/installation/

//----- Import i18n
import i18n from './i18n/i18n'

//----- Import vue-multiselect
import Multiselect from 'vue-multiselect'
//https://www.npmjs.com/package/@vueform/multiselect


//----- Import vue-cookie-comply
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

//----- Import vue-gtag (Google Analytics)
import VueGtag from "vue-gtag";
// https://matteo-gabriele.gitbook.io/vue-gtag/auto-tracking

import { VueReCaptcha } from 'vue-recaptcha-v3'

// import vue-awesome-paginate
import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"

createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(VueCookieComply)
.use(VueGtag, {
    config: { 
      id: "G-MYEPB3RBSG",
    },
  }, router)
.use(VueReCaptcha, { siteKey: '6LfKAKoiAAAAANvQhZS4TNhGPKQXSBxoYjP6NKNo' })
.use(VueAwesomePaginate)
.component('Datepicker', Datepicker)
.component('multiselect', Multiselect)
.mount('#app')
