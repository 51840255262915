import router from "@/router";
import { collection, addDoc } from "firebase/firestore";
import { db } from '@/firebase'

export default {
    state: {
        country: null,
        area: null,
        yachtType: null,
        date: null,
        flexibility: null,
        view: null
      },

    getters: {
        country: state => state.country,
        area: state => state.area,
        yachtType: state => state.yachtType,
        date: state => state.date,
        flexibility: state => state.flexibility,
        view: state => state.view,
    },
    
    mutations: {
        setCountry(state, payload){state.country = payload},
        setArea(state, payload){state.area = payload},
        setYachtType(state, payload){state.yachtType = payload},
        setDate(state, payload){state.date = payload},
        setFlexibility(state, payload){state.flexibility = payload},
        setView(state, payload){state.view = payload}
    },

    actions: {
        clearStore({ commit }){
            commit("setCountry", null)
            commit("setArea", null) 
            commit("setYachtType", null) 
            commit("setDate", null) 
            commit("setFlexibility", null) 
            commit("setView", null)          
        },
        callSearch({commit, dispatch}, payload){
            // Template: @click="this.callSearch({'country': this.country, 'area': this.area, date': this.dateRange, 'yachtType' : this.yachtType, 'view' : this.view})"
            dispatch('clearStore')
            const saveSearch = {clickDate : new Date()}
            if (payload.area){
                saveSearch.area = payload.area
                commit('setArea', payload.area)
            }
            if (payload.country){
                saveSearch.country = payload.country
                commit('setCountry', payload.country)
            }
            if (payload.date){
                saveSearch.date = payload.date
                commit('setDate', payload.date)
            }
            if (payload.yachtType){
                saveSearch.yachtType = payload.yachtType,
                commit('setYachtType', payload.yachtType)
            }
            if (payload.view){
                saveSearch.view = payload.view,
                commit('setView', payload.view)
            }
            if (payload.flexibility){
                saveSearch.flex = payload.flexibility
                commit('setFlexibility', payload.flexibility)
            }
            //Route to Landingpages for Search Results
            if (payload.view === 'Lastminute') {
                saveSearch.targetSite = '/yachtcharter/search-results-lastMinute',
                router.push('/yachtcharter/search-results-lastMinute')
            } else if (payload.country === 'IT') {
                saveSearch.targetSite = '/yachtcharter/search-results-italy',
                router.push('/yachtcharter/search-results-italy')
            } else if ( payload.country === 'HR') {
                saveSearch.targetSite = '/yachtcharter/search-results-croatia',
                router.push('/yachtcharter/search-results-croatia')
            } else  {
                saveSearch.targetSite = '/yachtcharter/search-results',
                router.push('/yachtcharter/search-results')
            }
            //Save Search - Criteria
            addDoc(collection(db, "search"), saveSearch)
        },
    },

    modules: {
    }
}