import i18n from '@/i18n/i18n'

export default {
    state: {
        language: 'de',
      },
      getters: {
        language: state => state.language,
      },

      mutations: {
        setLanguage(state, payload){
          state.language = payload
          // console.log(' Store - Language changed to: ', state.language)
        },
      },
      actions: {
        
      },
      modules: {
      }
}