
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA5zCbNS-I5BigJcpk6-v4J_CDs-0CiUzE",
    authDomain: "yachtchecker-f7f88.firebaseapp.com",
    projectId: "yachtchecker-f7f88",
    storageBucket: "yachtchecker-f7f88.appspot.com",
    messagingSenderId: "384326770815",
    appId: "1:384326770815:web:49c864bee16bf44cd09f4a",
    measurementId: "G-MYEPB3RBSG"
};

//Initial Firebase
const app = initializeApp(firebaseConfig);

//Initial Firebase Services
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db }