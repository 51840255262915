import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
// import store from '../store'
// import { useStore } from 'vuex';
// import store from '../store'

// const store = useStore()
// console.log("i18n: ", store)
const language = "de";
// const language = localStorage.getItem("language") || "en";

const locales = {
  de: require("./de.json"),
  en: require("./en.json"),
};

const i18n = createI18n({
    locale: language,
    fallbackLocale: 'en',
    messages: locales
  })

export default i18n