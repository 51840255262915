import { createStore } from 'vuex'
import language from './modules/language'
import user from './modules/user'
import search from './modules/search'

export default createStore({
  modules: {
    language,
    user,
    search,
  }
})